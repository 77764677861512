.bottom-menu {
  justify-content: center;
  align-items: center;
  gap: 90px;
  background-color: #fff !important;
  /* border-top-right-radius: 20px; */
  /* border-top-left-radius: 20px; */
  margin-top: 20px 0 30px 0;
  padding: 10px 0 28px 0;
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}
.bottom-menu-btn {
  color: #989898;
  font-size: 15px;
  font-weight: 400;
  flex-basis: 33.3333%;
  text-align: center;
}
.bottom-menu-btn a {
  color: #989898;
}
.bottom-menu-submit {
  border-radius: 50%;
  border: 5px solid #C85500;
  background-color: var(--main-color);
  color: #fff;
  text-align: center;
  text-shadow: 0px 1px 0px #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 110.57%;
  padding: 20px 25px;
  display: block;
}
.float-btn {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 50%;
  z-index: 100;
  background-color: #fff;
  overflow: hidden;
}

@media screen and (max-width: 610px) {
  .bottom-menu {
    display: flex;
  }
}

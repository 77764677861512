.container {
  margin: 80px 0;
  width: 100%;
}
.title {
  color: #3b3935;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}
.sub-title {
  color: #3b3935;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
}
.support-method-container {
  margin-top: 16px;
  height: 202px;
  width: 100%;
  display: flex;
  gap: 30px;
  justify-content: space-between;
}
.support-method {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #fff7ef;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}
.support-method span {
  color: #ff6c00;
  font-size: 24px;
  font-weight: 700;
}

@media screen and (max-width: 1400px) {
  .title {
    font-size: 36px;
  }
}

@media screen and (max-width: 1200px) {
  .title {
    font-size: 32px;
  }
}

@media screen and (max-width: 976px) {
  .title {
    font-size: 28px;
  }
}

@media screen and (max-width: 480px) {
  .container {
    margin: 40px 0;
  }
  .title {
    font-size: 22px;
  }
  .sub-title {
    font-size: 18px;
  }
  .support-method span {
    font-size: 20px;
  }
  .support-method svg {
    width: 60px;
    height: 60px;
  }
  .support-method-container {
    gap: 12px;
    height: 123px;
  }
}

/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.not-found-text {
  color: var(--black, #3b3935);
  font-family: Shopee Display;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  line-height: normal;
  text-align: center;
}

.container button {
  text-transform: uppercase;
  color: var(--main-color);
  border-color: var(--main-color);
  font-size: 18px;
  height: 100%;
  margin-top: 20px;
}

.container img {
  max-width: 100%;
  padding: 2rem 0;
}

@media screen and (max-width: 500px) {
  .not-found-text {
    font-size: 24px;
  }

  .container button {
    font-size: 16px;
  }
}

.login-modal {
  color: #05422c;
  font-size: 40px;
  font-weight: 700;
}

.login-logo {
  display: flex;
  justify-content: center;
}

.login-content h2 {
  text-align: center;
  margin-top: 24px;
}

.policy strong {
  color: var(--main-color);
  text-decoration: underline;
}
.login-btn {
  display: block;
  margin: 0 auto;
  border-radius: 10px;
  background: var(--main-color);
  border: none;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 12px 48px;
  cursor: pointer;
}

.navigate-login {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
}
.navigate-login span {
  color: var(--main-color);
  font-weight: 700;
  cursor: pointer;
}

.forgot-password {
  text-align: right;
  color: #1139c6;
  cursor: pointer;
  font-weight: 700;
}

.other-login {
  text-align: center;
  color: #777;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 30px 0;
}
.other-login span {
  display: block;
}
.other-login .devide {
  height: 1px;
  background: #777;
  width: calc((100% - 36px - 20px) / 2);
}

.social-login {
  display: flex;
  gap: 40px;
  justify-content: center;
  margin-bottom: 40px;
}
.social-login button {
  display: block;
  border: none;
  background: transparent;
  cursor: pointer;
}
.social-login button svg {
  width: 44px;
  height: 44px;
}

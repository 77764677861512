.login-modal {
  color: #05422c;
  font-size: 40px;
  font-weight: 700;
}

.login-content > svg {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}

.login-content h2 {
  text-align: center;
  margin-top: 24px;
  margin-bottom: 10px;
}
.login-content p {
  color: #8c8c8c;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  margin: 24px 0;
}

.login-btn {
  display: block;
  margin: 0 auto;
  border-radius: 10px;
  background: var(--main-color);
  border: none;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 10px 48px;
  cursor: pointer;
  margin-top: 32px;
  height: 100%;
}

.tab-label {
  color: var(--main-color);
  font-size: 18px;
  font-weight: 700;
  font-family: 'Calibri', sans-serif;
}

.otp-submit {
  border-radius: 10px;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  font-size: 16px;
  font-weight: 700;
  background-color: transparent;
  width: 100%;
  padding: 5px;
  height: 100%;
}

/* Verify */
.form-container > label {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.message {
  line-height: 1.5;
  text-align: justify;
}

.message h5 {
  font-size: 16px;
  font-weight: 600;
}
.message p {
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
}

.container button {
  font-weight: 700;
  margin-top: 40px;
  padding: 10px 30px !important;
  height: fit-content !important;
}

.ant-tabs-nav-list {
  width: 100% !important;
}

.ant-tabs-tab {
  width: 50% !important;
}

.otp-err {
  color: #ff4d4f;
  font-size: 16px;
}

.modal-header__logo {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .container {
    min-width: fit-content;
    width: 75vw;
  }
}

@media screen and (max-width: 375px) {
  .container {
    margin-bottom: 30px;
    margin-top: 30px;
  }
}

.payment-layout {
  background: #f2f2f2;
  color: #05422c;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.payment-wrapper {
  padding: 0 170px;
  min-height: 76vh;
}

.payment-support {
  position: fixed;
  bottom: 30px;
  right: 20px;
}

.breadcrumb {
  color: #05422c;
  font-size: 16px;
  font-weight: 400;
  margin: 24px 0;
}

.support-icon:hover {
  cursor: pointer;
}

.mobile-buffer {
  width: 100%;
  height: 130px;
  background-color: #fff;
  display: none;
}

/* Responsive */
@media screen and (max-width: 992px) {
  .payment-wrapper {
    padding: 0 80px;
  }

  .payment-support {
    right: 5px;
  }

  .payment-support img {
    width: 60px;
  }
}

@media screen and (max-width: 610px) {
  .payment-wrapper {
    padding: 0 16px;
  }
  .support-icon {
    display: none;
  }
  .mobile-buffer {
    display: block;
  }
}

.guide-modal {
  color: #05422c;
  font-weight: 700;
}

.guide-content h2 {
  font-size: 24px;
  margin-bottom: 16px;
}
.guide-content p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 4px;
}

.guide-page {
  border-radius: 10px;
  background: #fff;
  padding: 24px;
  margin-bottom: 30px;
}

.guide-page h2 {
  font-size: 30px;
  font-weight: 700;
}

.guide-tabs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 24px 0;
}

.guide_tabs__item {
  border: none;
  border-right: 1px solid #ddd;
  background: #f1f1f3;
  font-size: 20px;
  font-weight: 400;
  flex-basis: 25%;
  padding: 16px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.guide_tabs__item.active {
  color: #fff;
  font-weight: 700;
  background-color: var(--main-color);
}

.guide-content {
  margin: 0 16px;
  transition: all 0.25s ease;
}

.guide-content__step {
  margin-bottom: 32px;
}
.guide-content__step:last-child {
  margin-bottom: 0;
}

.guide-content__step p {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 16px;
}

.guide-content__step p span {
  font-weight: 700;
  color: var(--main-color);
}

.guide-content__step img {
  display: block;
  margin: 0 auto;
  object-position: center;
  max-width: 648px;
  margin-top: 32px;
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .guide-page h2 {
    font-size: 28px;
  }
  .guide_tabs__item {
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
  }
  .guide-content__step p {
    font-size: 18px;
  }
}

@media screen and (max-width: 1200px) {
  .guide-page h2 {
    font-size: 26px;
  }
  .guide_tabs__item {
    font-size: 14px;
  }
  .guide-content__step p {
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .guide-page h2 {
    font-size: 24px;
  }
  .guide_tabs__item {
    flex-basis: 50%;
    border-bottom: 1px solid #ddd;
  }
  .guide-content {
    margin: 0;
  }
  .guide-content__step img {
    margin-top: 16px;
  }
}

@media screen and (max-width: 600px) {
  .guide-page {
    padding: 24px 8px 10px 8px;
  }
  .guide-page h2 {
    font-size: 20px;
  }
  .guide_tabs__item {
    padding: 16px;
    min-height: 66px;
  }
}

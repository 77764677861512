.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}
.content {
  color: #696969;
  font-size: 18px;
  font-weight: 400;
}
.content img {
  width: 40%;
  height: auto;
}
.content ul {
  padding-left: 3%;
}

@media screen and (max-width: 1024px) {
  .content img {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .container {
    gap: 20px;
  }
}

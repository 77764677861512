/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  margin: 20px 0 30px 0;
  font-family: serif;
}

.title {
  text-align: center;
  font-size: 24px;
}

@media screen and (max-width: 577px) {
  .title {
    font-size: 20px;
  }
}

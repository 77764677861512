#root {
  position: relative;
}

.modal {
  position: fixed;
  inset: 0;
  z-index: 1;
}

.modal-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 2;
}
.modal-header {
  font-family: Calibri;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-header span {
  display: inline-block;
  padding: 24px 2px 24px 24px;
}
.modal-header span:hover {
  cursor: pointer;
}
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: max-content;
  background-color: #fff;
  z-index: 100;
  border-radius: 10px;
  padding: 24px;
}

.modal-content-mobile {
  top: 40%
}

.modal-table {
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100%;
  color: #05422c;
  font-size: 32px;
  font-weight: 700;
}
.modal-table thead th {
  border-bottom: 1px solid #ddd;
  padding: 16px 0;
}
.modal-table tbody td {
  text-align: center;
  font-size: 24px;
  border-bottom: 1px solid #ddd;
  padding: 16px 0;
}
.modal-table tbody tr:last-child td {
  border: none;
}

@media screen and (max-width: 610px) {
  .modal-content {
    width: 85vw;
  }
}

.container {
    font-size: 16px;
}

.card {
    margin-top: 2.5rem;
    background-color: #ffffff;
}

.card-body {
    padding: 1.5rem;
    text-align: center;
}

.card-body h5 {
    padding: 1.25rem;
    font-size: 16px;
}
.login-modal {
  color: #05422c;
  font-size: 40px;
  font-weight: 700;
}

.login-logo {
  display: flex;
  justify-content: center;
}

.login-content h2 {
  text-align: center;
  margin-top: 24px;
}

.policy strong {
  color: var(--main-color);
  text-decoration: underline;
}
.login-btn {
  display: block;
  margin: 0 auto;
  border-radius: 10px !important;
  background: var(--main-color);
  border: none;
  color: #fff;
  font-size: 17px !important;
  font-weight: 700;
  padding: 10px 36px !important;
  cursor: pointer;
  height: auto !important;
}

.navigate-login {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
}
.navigate-login span {
  color: var(--main-color);
  font-weight: 700;
  cursor: pointer;
}

.container {
  width: 30vw;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 12%;
  min-width: 500px;
  margin-top: 50px;
}

.title {
  padding: 1rem 0 1rem;
  text-align: center;
  text-transform: uppercase;
}

.message {
  line-height: 1.5;
  text-align: justify;
}

.message h5 {
  font-size: 16px;
  font-weight: 600;
}
.message p {
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .container {
    min-width: fit-content;
    width: 75vw;
  }
}

@media screen and (max-width: 375px) {
  .container {
    margin-bottom: 30px;
  }
}

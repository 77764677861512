.noti-modal {
  color: #05422c;
  font-size: 40px;
  font-weight: 700;
}

.failed,
.success {
  display: block;
  margin: 40px auto;
}

.noti-content p {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin: 24px 0;
}

.home-page {
  color: #05422c;
  background-color: #f5f5f5;
}

.devide {
  background: #e4e4e4;
  height: 2px;
  width: 85%;
  margin: 64px auto;
}

/* Banner */
.home-banner {
  width: 100%;
  :global(.swiper-pagination-bullet) {
    background: transparent;
    background-image: url(apps/shell/src/assets/images/slider-bullet.png);
    background-size: contain;

    opacity: 1;
  }
  :global(.swiper-pagination-bullet-active) {
    background: transparent;
    background-image: url(apps/shell/src/assets/images/slider-bullet-active.png);
    background-size: contain;
    width: 42px;
    height: 8px;
    border-radius: unset;
    opacity: 1;
  }
}
.swiper-container {
  width: 100%;
}
.swiper-item img {
  object-fit: cover;
  height: auto;
  width: 100%;
}

.wrapper-game-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.promote-game {
  width: 100%;
  margin-top: 62px;
  padding: 0 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.promote-game-title {
  position: relative;
  padding-left: 20px;
}

.promote-game-title h2::before {
  content: '';
  position: absolute;
  background-color: var(--main-color);
  top: 0;
  bottom: 0;
  width: 8px;
  left: 0;
}

.promote-game h2 {
  font-size: 32px;
  font-weight: 700;
}

.list-game {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 32px;
  flex-wrap: wrap;
}

.game-item {
  /* max-width: 350px; */
  flex-basis: calc((100% - 48px) / 3);
}
.game-item p {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #3b3935;
}

.game-card {
  position: relative;
  border-radius: 5px;
}
.game-card img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  border-radius: 5px;
  min-width: 400px;
}

.game-card button {
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  border-radius: 10px;
  background: var(--main-color);
  position: absolute;
  bottom: 16px;
  right: 16px;
  cursor: pointer;
}

.release-game {
  margin-bottom: 62px;
  padding: 0 170px;
  /* width: 100%; */
}

.release-game__title {
  display: flex;
  justify-content: space-between;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  gap: 24px;
  margin-top: 65px;
}

.release-game-header {
  position: relative;
  padding-left: 20px;
}

.release-game-header h2 {
  font-size: 32px;
}

.release-game-header h2::before {
  content: '';
  position: absolute;
  background-color: var(--main-color);
  top: 0;
  bottom: 0;
  width: 8px;
  left: 0;
}

.search-game {
  position: relative;
  right: 0;
  bottom: 4px;
}

.search-game input {
  background-color: #fff !important;
}

.search-game img {
  display: block;
  position: absolute;
  left: 15px;
  top: 15px;
}

.release-game__title input {
  border-radius: 5px;
  border: 1px solid #c6c6c6;
  background: #f7f7f7;
  padding: 11px 12px;
  padding-left: 44px;
  font-size: 14px;
  color: #b4b4b4;
  font-weight: 400;
  position: relative;
  outline: none;
}

.release-game__title input::placeholder {
  font-style: italic;
  color: #b4b4b4;
}

.show-more-wrapper {
  width: 100%;
  text-align: center;
}

.show-more {
  font-size: 18px;
  margin-top: 40px;
  position: relative;
  padding: 10px;
  border: 1px solid;
  border-color: #ddd;
  border-radius: 10px;
  color: #aaa;
}

.show-more::before,
.show-more::after {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 18px;
  padding-left: 100%;
  padding-right: 100%;
}

.show-more::before {
  left: 100%;
  margin-left: 15px;
}

.show-more::after {
  right: 100%;
  margin-right: 15px;
}

.payment-support {
  z-index: 1;
  position: fixed;
  right: 20px;
  bottom: 30px;
}

/* Responsive */
@media screen and (min-width: 1400px) {
  .list-game {
    width: 1100px;
  }

  .promote-game-title {
    width: 1100px;
  }
}

@media screen and (max-width: 1400px) {
  .release-game {
    width: 100%;
  }

  .list-game {
    min-width: 100%;
  }

  .promote-game-title {
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .list-game {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .game-item {
    flex-basis: calc((100% - 24px) / 2);
  }
  .release-game__title {
    text-align: left;
  }
  .promote-game,
  .release-game {
    padding: 0 80px;
  }

  .payment-support {
    right: 5px;
  }

  .payment-support img {
    width: 60px;
  }

  .search-game {
    position: relative;
    bottom: 5px;
  }

  .game-card img {
    min-width: 350px;
  }
}

@media screen and (max-width: 768px) {
  .swiper-container {
    margin-bottom: 60px;
  }

  .swiper-item img {
    object-fit: cover;
  }

  .show-more::before,
  .show-more::after {
    padding-right: 0;
  }

  .promote-game {
    margin-top: 0;
  }
}

@media screen and (max-width: 610px) {
  .mobile-buffer {
    width: 100%;
    background-color: #f5f5fa;
    height: 130px;
    /* display: none; */
  }

  /* Banner */
  .swiper-item img {
    object-fit: cover;
  }

  .swiper-item {
    /* height: 200px; */
  }

  .promote-game {
    margin-top: -25px;
  }

  /* End banner */

  .game-item {
    flex-basis: 100%;
  }
  .game-item p {
    font-size: 20px;
  }

  .release-game__title,
  .promote-game h2 {
    font-size: 20px;
  }

  .promote-game-title,
  .release-game__title {
    margin-bottom: 24px;
  }

  .promote-game-title h2 {
    font-size: 23px;
  }

  .release-game-header h2 {
    font-size: 23px;
  }

  .show-more {
    text-align: right;
    margin-top: 30px;
  }

  .game-item p {
    font-size: 18px;
  }

  .release-game__title {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 10px;
    margin-top: 40px;
  }

  .release-game__title input {
    padding-left: 36px;
    padding-right: 6px;
    max-width: 190px;
  }

  .release-game__title h2 {
    flex-basis: 50%;
  }

  .release-game__title input {
    max-width: 180px;
  }

  .search-game {
    bottom: 0px;
  }

  .search-game img {
    top: 9px;
    left: 10px;
  }

  .promote-game,
  .release-game {
    padding: 0 16px;
  }

  .list-game {
    row-gap: 40px;
    margin-top: 0px;
  }

  .devide {
    margin: 48px auto;
  }
  .release-game {
    margin-bottom: 40px;
  }

  .payment-support {
    display: none;
  }

  .release-game-header,
  .promote-game-title {
    padding-left: 15px;
  }

  .show-more::before,
  .show-more::after {
    padding-left: 0;
  }

  .release-game__title input {
    max-width: 165px;
  }

  .game-card img {
    min-width: unset;
  }
}

@media screen and (max-width: 390px) {
  .release-game__title h2 {
    font-size: 21px;
  }

  .promote-game-title h2 {
    font-size: 21px;
  }

  .release-game__title input {
    max-width: 160px;
  }
}

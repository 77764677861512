.userInfo-page {
  background-color: #fff;
  padding: 24px;
  color: #05422c;
  margin-bottom: 30px;
  border-radius: 10px;
}

.userInfo-page h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 24px;
}
.userInfo-page .mobile-avatar,
.section-title {
  display: none;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 24px;
  position: relative;
  font-family: 'Calibri', sans-serif;
}
.section-title {
  margin-top: 48px;
  display: block;
  padding-left: 20px;
}

.user-avatar img {
  display: block;
  margin: 0 auto;
  margin-bottom: 16px;
  margin-top: 8px;
}
.user-avatar button {
  display: block;
  margin: 0 auto;
  border: none;
  border-radius: 10px;
  background: var(--main-color);
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 14px 36px;
}

.userInfo-page .mobile-avatar::before,
.section-title::before {
  position: absolute;
  width: 8px;
  content: '';
  background-color: var(--main-color);
  top: 0;
  bottom: 0;
  left: 0;
}

.submit-btn {
  border: none;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 5px;
  background: var(--main-color);
  padding: 16px 48px;
}

@media screen and (max-width: 768px) {
  .section-title,
  .userInfo-page .mobile-avatar {
    font-size: 20px;
  }
}

@media screen and (max-width: 610px) {
  .user-avatar svg {
    width: 160px;
    height: 160px;
  }
  .userInfo-page h2 {
    display: none;
  }
  .userInfo-page .mobile-avatar {
    display: block;
  }

  .userInfo-page .mobile-avatar,
  .section-title {
    padding-left: 20px;
  }

  .userInfo-page .mobile-avatar::before,
  .user-info p::before {
    display: block;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}
.collapse {
  width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  background: #3b3935;
}
.title {
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.expand-icon svg {
  fill: #fff;
  width: 32px;
  height: 30px;
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 18px;
  }
  .expand-icon svg {
    width: 24px;
    height: 24px;
  }
}

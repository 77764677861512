.gamelist-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.gameList-page {
  width: 1100px;
  margin-top: 10px;
}

.list-game {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px 24px;
  margin-top: 24px;
  flex-wrap: wrap;
}

.game-item {
  flex-basis: calc((100% - 48px) / 3);
}

.game-item p {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #05422c;
}

.game-card {
  position: relative;
  border-radius: 5px;
}
.game-card img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  min-width: 400px;
  border-radius: 5px;
}

.game-card button {
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  border-radius: 10px;
  background: var(--main-color);
  position: absolute;
  bottom: 16px;
  right: 16px;
  cursor: pointer;
}

.release-game {
  margin-bottom: 48px;
  width: 100%;
}
.release-game__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 32px;
  font-weight: 700;
}

.release-game-header {
  position: relative;
  padding-left: 20px;
}

.release-game-header span::before {
  position: absolute;
  width: 8px;
  top: 0;
  bottom: 0;
  background-color: var(--main-color);
  left: 0;
  content: '';
}

.search-game {
  position: relative;
  right: 0;
  bottom: 3px;
}

.search-game input {
  background-color: #fff !important;
}

.search-game img {
  display: block;
  position: absolute;
  left: 15px;
  top: 15px;
}

.release-game__title input {
  border-radius: 5px;
  border: 1px solid #c6c6c6;
  background: #f7f7f7;
  padding: 11px 12px;
  padding-left: 44px;
  font-size: 14px;
  color: #b4b4b4;
  font-weight: 400;
  position: relative;
  outline: none;
  max-width: 190px;
}

.release-game__title input::placeholder {
  font-style: italic;
  color: #b4b4b4;
}

/* Responsive */
@media screen and (max-width: 992px) {
  .list-game {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .game-item {
    flex-basis: calc((100% - 24px) / 2);
  }
  .release-game__title {
    text-align: left;
  }

  .game-card img {
    min-width: 350px;
  }
}

@media screen and (max-width: 610px) {
  .gameList-page {
    margin-top: 0px;
  }
  .game-item {
    flex-basis: 100%;
  }
  .game-item p {
    font-size: 20px;
  }
  .release-game__title,
  .promote-game h2 {
    font-size: 24px;
  }

  .game-item p {
    font-size: 20px;
  }

  .release-game__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
  }

  .release-game__title span {
    font-size: 21px;
  }
  .release-game__title input {
    padding-left: 36px;
    padding-right: 6px;
  }

  .search-game input {
    max-width: 180px;
  }

  .search-game {
    position: relative;
    bottom: 0;
  }
  .search-game img {
    top: 9px;
    left: 10px;
  }

  .list-game {
    row-gap: 40px;
  }

  .release-game-header {
    padding-left: 15px;
  }

  .release-game__title input {
    max-width: 165px;
  }

  .game-card img {
    min-width: unset;
  }
}

@media screen and (max-width: 390px) {
  .release-game__title span {
    font-size: 20px;
  }

  .search-game input {
    max-width: 160px;
  }
}

.payment-container {
  background-color: #f0f0f0;
}
input:checked {
  accent-color: var(--main-color) !important;
  mix-blend-mode: multiply;
}

.breadcrumb {
  color: #05422c;
  font-size: 16px;
  font-weight: 400;
  padding: 24px 0;
}

.payment-content {
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto;
  padding: 24px;
  margin-bottom: 100px;
}
.content-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.content-top__user {
  display: flex;
  gap: 24px;
  width: 100%;
}

.user-info {
  gap: 24px;
}

.user-info img {
  width: 100px;
  border-radius: 10px;
}

.user-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.user-coint {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-coint h4 {
  color: #05422c;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}
.coint-value {
  color: var(--main-color);
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 8px;
}

.coint-exchange {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #05422c;
  font-size: 19px;
  font-weight: 400;
  border-radius: 10px;
  border: 1px solid var(--text, #05422c);
  padding: 10px;
  margin-top: 8px;
  width: fit-content;
  cursor: pointer;
}

.transaction {
  color: #05422c;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.transaction-guide {
  margin-bottom: 12px;
  width: fit-content;
  padding-bottom: 2px;
  border-bottom: 1px solid #05422c;
  cursor: pointer;
}

.transaction-history {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 8px;
  border-radius: 5px;
  border: 1px solid #05422c;
  text-decoration: none;
  color: #05422c;
}
/* Payment method */
.choose-payment {
  color: #05422c;
  font-size: 30px;
  font-weight: 700;
  margin: 24px 0;
  padding-left: 20px;
}

.choose-payment::before {
  position: absolute;
  content: '';
  width: 8px;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--main-color);
}

.payment-method {
  display: flex;
  gap: 24px;
  margin-bottom: 15px;
}
.payment-method-item {
  flex-basis: 25%;
  justify-content: center;
  border: 1px solid #ddd;
  color: #05422c;
  font-size: 20px;
  font-weight: 400;
  padding: 14px 8px;
  position: relative;
  display: flex;
  background-color: #e3e3e3;
  align-items: center;
  border-radius: 10px 10px 0 0;
  gap: 10px;
  cursor: pointer;
}

.payment-method-item-mobile {
  justify-content: space-between;
}

.payment-method-info {
  display: flex;
  gap: 10px;
  align-items: center;
}

.payment-method-active {
  background-color: #fff;
  border-top: 2px solid var(--orange, #ff6c00);
  border-right: 2px solid var(--orange, #ff6c00);
  border-left: 2px solid var(--orange, #ff6c00);
  border-bottom: none;
}

/* .payment-method-item:hover,
.bank-item:hover {
  border: 1px solid #DDD;
  cursor: pointer;
} */

.payment-method-item:active {
  background-color: #fff;
}

.payment-method-item img {
  /* display: block;
  margin: 0 auto; */

  width: 50px;
  height: 50px;
}

.payment-method-item span {
  display: inline-block;
  margin-top: 4px;
  color: var(--main-color);
  font-size: 16px;
  font-weight: 700;
}
.payment-method-item .promote-tag {
  position: absolute;
  top: 0;
  right: 12px;
  font-size: 13px;
  color: #fff;
  font-weight: 700;
  border-radius: 0px 0px 14.722px 14.722px;
  background: var(--main-color);
  margin: 0;
  padding: 4px 10px;
  animation: colorTransition 0.3s infinite;
}
@keyframes colorTransition {
  from {
    background-color: var(--main-color);
  }
  to {
    background-color: #da0707;
  }
}

/* Exchange block */
.exchange-block {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 220px;
}
.exchange-table,
.exchange-submit {
  flex-basis: 50%;
}
.exchange-table table {
  width: 100%;
  padding: 24px;
  border-radius: 10px;
  border: 1px solid #ddd;
  color: #05422c;
  font-size: 18px;
  font-weight: 700;
  border-collapse: collapse;
}

.exchange-submit p,
.exchange-table p {
  color: #05422c;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  margin: 24px 0 16px 0;
}

.exchange-submit p {
  font-weight: 700;
  padding-left: 20px;
}

.exchange-submit > p::before {
  position: absolute;
  width: 8px;
  content: '';
  background-color: var(--main-color);
  top: 0;
  bottom: 0;
  left: 0;
}

.exchange-table thead tr th {
  /* width: 33%; */
  padding: 24px 0;
}

.exchange-table tbody tr td {
  /* width: 33%; */
  padding: 24px;
  text-align: center;
}

.exchange-table tbody tr td p {
  font-size: 18px;
  font-weight: 700;
}

.exchange-table tbody tr td input {
  width: 20px;
  height: 20px;
}
.exchange-table tbody tr td input:hover {
  cursor: pointer;
}

.exchange-table table tr {
  border-bottom: 1px solid #ddd;
}

.bank-option {
  display: flex;
  gap: 40px;
  align-items: center;
  margin-bottom: 24px;
  justify-content: center;
}
.bank-item {
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 12px 22px;
  display: flex;
  align-items: center;
  gap: 16px;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
}
.bank-item.active {
  border: 3px solid #56b947;
}

.bank-item img {
  display: block;
  width: 60px;
  height: 60px;
}
.bank-item span {
  display: block;
  color: #05422c;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
}

.bank-item-text-active {
  color: #fff !important;
}

.bank-item-active {
  background-color: var(--main-color);
}

/* Option 1: Banking QR */
.bank-tranfer {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 24px;
  margin-bottom: 40px;
}
.bank-tranfer h5 {
  color: #da0707;
  font-size: 13px;
  font-weight: 400;
  margin-top: 18px;
}

.va-confirm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.va-confirm p {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
}

.va-confirm button {
  border-radius: 5px;
  background: var(--main-color);
  padding: 10px 24px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.pay-block {
  display: flex;
  gap: 10px;
  align-items: baseline;
}
.pay-option {
  color: #05422c;
  font-size: 16px;
  font-weight: 700;
  min-width: 50px;
}
.pay-step {
  list-style: none;
  padding: 0;
  margin: 0;
}

.pay-step > div {
  gap: 12px;
  display: flex;
}

.pay-step li {
  color: #05422c;
  font-size: 14px;
  font-weight: 400;
  padding: 3px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.pay-step li p {
  color: #05422c;
  font-size: 16px;
  font-weight: 400;
  padding: 6px 0;
  margin: 0;
}
.pay-step li button {
  border: none;
  border-radius: 5px;
  background: #d4d4d4;
  padding: 6px 10px;
  color: #5e5e5e;
  font-weight: 700;
  cursor: pointer;
}
.pay-step li img {
  display: block;
  margin: 0 auto;
}

.bank-owner {
  color: #05422c;
  font-size: 16px;
  font-weight: 400;
  margin-top: -78px;
  text-align: center;
}
.pay-step li span {
  display: inline-block;
  margin: 1px 0;
}
.payment-info {
  padding: 8px 24px 24px 24px;
  border-radius: 15px;
  border: 1px solid #ddd;
  text-align: center;
}
.payment-info__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #ddd;
}
.payment-submit {
  border-radius: 5px;
  background: var(--main-color);
  padding: 14px 32px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border: none;
  margin-top: 40px;
}
.payment-submit:hover {
  cursor: pointer;
}

.discount {
  display: flex;
  align-items: center;
}

.discount span {
  display: block;
  position: relative;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  width: 40px;
  text-align: center;
}
.discount img {
  display: block;
  position: absolute;
}

.w-10 {
  width: 10%;
}
.w-30 {
  width: 30%;
}
.w-100 {
  width: 100%;
}

/* Payment package */
.package-title {
  position: relative;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 24px;
  margin-top: 24px;
}

.package-title span {
  padding-left: 20px;
}

.package-title span::before {
  position: absolute;
  content: '';
  width: 8px;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--main-color);
}

.package-wrapper {
  display: flex;
  gap: 24px;
}

.package-group {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  gap: 2px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.package-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
}

.package-info__discount {
  display: none;
}

.package-bxu {
  font-weight: 700;
  font-size: 24px;
  color: #3b3935;
  text-align: start;
}

.package-bonus {
  font-weight: 400;
  font-size: 14.5px;
  color: #b6b6b6;
  min-height: 20px;
  text-align: start;
}

.package-currency {
  font-weight: 700;
  color: #ff6c00;
  font-size: 18px;
  padding: 8px 0;
  border: 1px solid #ff6c00;
  border-radius: 5px;
  background-color: rgba(255, 154, 0, 0.1);
  margin-top: 6px;
  text-align: center;
  cursor: pointer;
}

.package-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  border-radius: 5px;
  background-color: var(--main-color);
  margin-top: 6px;
}

.package-selected img {
  width: 20px;
  height: 20px;
}

.payment-confirm-title {
  font-size: 24px;
  font-weight: 700;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.payment-confirm-title::before {
  content: '';
  width: 8px;
  background-color: var(--main-color);
  top: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.payment-rate-wrapper {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 24px;
  border-radius: 10px;
}

.button-wrapper {
  width: 100%;
  background-color: transparent;
  border: none;
}

/* Payment QR */
.bank-info__item {
  flex-basis: 50%;
}

.qr-wrapper {
  border: 2px solid #3b3935;
  border-radius: 10px;
  padding: 5px;
}

.img-discount {
  display: none;
}

.package-info__discount-mobile {
  display: none;
}

/* Discount for screen > 1400px */
@media screen and (min-width: 1201px) {
  .package-info__discount {
    display: block;
  }

  .package-info__discount-number {
    position: absolute;
    top: 33px;
    font-size: 16px;
    color: #fff;
    font-family: Calibri Bold;
    text-align: center;
    width: 41px;
  }
}

@media screen and (max-width: 1390px) and (min-width: 1200px) {
  .package-group {
    padding: 20px 8px;
  }

  .package-info__discount-number {
    top: 31px;
  }

  .package-bxu {
    font-size: 20px;
  }

  .package-bonus {
    font-size: 13px;
  }
}

@media screen and (max-width: 1230px) and (min-width: 1201px) {
  .package-info__discount-img {
    width: 31px;
  }

  .package-info__discount-number {
    top: 34px;
    font-size: 14px;
    width: 32px;
  }
}

/* Discount for screen 800px => 1200px */
@media screen and (max-width: 1201px) and (min-width: 611px) {
  .package-info__discount {
    display: block;
  }
  .package-info__discount-number {
    position: absolute;
    top: 34px;
    font-size: 16px;
    color: #fff;
    font-family: Calibri Bold;
    text-align: center;
    width: 41px;
  }

  .package-group {
    padding: 20px 10px;
  }
}

@media screen and (max-width: 1100px) {
  .package-bxu {
    font-size: 19px;
  }

  .package-bonus {
    font-size: 12px;
  }

  .package-info__discount-number {
    top: 32px;
  }
}

@media screen and (max-width: 840px) and (min-width: 780px) {
  .package-group {
    padding: 20px 8px;
  }
  .package-info__discount-img {
    width: 31px;
  }

  .package-info__discount-number {
    top: 31px;
    font-size: 14px;
    width: 32px;
  }
}

@media screen and (max-width: 1400px) {
  .package-currency {
    font-size: 14px;
    padding: 8px 0;
  }
}

@media screen and (max-width: 776px) {
  .package-bxu {
    font-size: 22px;
  }
  .package-bonus {
    font-size: 13px;
  }
  .package-currency {
    font-size: 12px;
  }

  .choose-payment {
    font-size: 22px;
  }
}

/* Discount for screen 611px => 779px */
@media screen and (max-width: 779px) and (min-width: 610px) {
  .package-bxu {
    font-size: 17px;
  }

  .package-bonus {
    font-size: 12px;
  }

  .package-info__discount-img {
    width: 31.5px;
  }

  .package-info__discount-number {
    width: 33px;
    top: 31px;
    font-size: 14px;
  }
}

@media screen and (max-width: 780px) and (min-width: 768px) {
  .package-bxu {
    font-size: 17px;
  }

  .package-bonus {
    font-size: 10.5px;
  }

  .package-info__discount-img {
    width: 29.5px;
  }

  .package-info__discount-number {
    width: 30px;
    top: 32px;
    font-size: 13px;
  }
}

@media screen and (max-width: 430px) {
  .package-group {
    padding: 20px 13px;
  }
  .package-bxu {
    font-size: 20px;
  }
  .package-bonus {
    font-size: 12px;
  }
  .package-currency {
    font-size: 13px;
  }
}

/* End payment package */

/* Responsive */

@media screen and (max-width: 1100px) {
  .payment-method {
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
  }

  .payment-method-item {
    flex-basis: 45%;
  }

  .exchange-block {
    flex-direction: column;
  }
}

@media screen and (max-width: 992px) {
  .payment-method-item {
    padding: 16px 4px;
  }

  .exchange-block {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .payment-method {
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
  }
  .payment-method-item {
    flex-basis: 45%;
  }
  .content-top {
    flex-direction: column;
  }
}

@media screen and (max-width: 700px) {
  .user-coint {
    flex-direction: column;
    align-items: start;
    justify-content: space-around;
  }
}

@media screen and (max-width: 610px) {
  .payment-method {
    flex-wrap: wrap;
    width: 100%;
    gap: 0px;
  }

  .content-top {
    margin-bottom: 24px;
  }

  .payment-confirm-title {
    margin-top: 0;
  }

  .bank-item {
    flex-direction: column;
    box-shadow: none;
    border: 1px solid#DDD;
    gap: 8px;
  }

  .bank-item img {
    width: 80px;
    height: 80px;
  }

  .payment-method-item {
    flex-basis: 100%;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #ddd;
  }

  .payment-method-active {
    border: none;
    border-bottom: 1px solid #ddd;
  }

  .promote-tag-mobile {
    width: 39px !important;
  }

  .payment-content {
    padding: 28px 8px;
    margin-bottom: 28px;
  }
  .payment-info__item {
    font-weight: 700;
  }
  /* Table */
  .exchange-table table {
    font-size: 14px;
  }

  .exchange-table tbody tr td p {
    font-size: 14px;
  }
  .discount span {
    font-size: 12px;
  }
  .bank-tranfer {
    padding: 24px 8px;
    margin-bottom: 28px;
    background-color: #fff;
    border: none;
  }

  .payment-rate-wrapper {
    width: 100%;
    background-color: #fff;
    padding: 28px 8px;
    border-radius: 10px;
  }
}

/* Discount for mobile */
@media screen and (max-width: 610px) {
  .package-bxu-mobile {
    display: flex;
    justify-content: space-between;
  }

  .img-discount {
    display: block;
  }

  .discount-number-mobile {
    position: absolute;
    top: 11.5px;
    font-size: 15px;
    color: #fff;
    min-width: 40px;
    display: flex;
    justify-content: center;
    font-family: Calibri;
  }

  .package-info__discount-mobile {
    display: block;
    position: absolute;
    top: 16px;
    right: 10px;
  }
}

@media screen and (max-width: 500px) {
  .package-bxu-mobile {
    display: flex;
    justify-content: space-between;
  }

  .bxu {
    font-size: 15px;
  }

  .img-discount {
    display: block;
    width: 30px;
    margin-top: -5px;
  }

  .package-group {
    padding: 20px 8px;
  }

  .discount-number-mobile {
    top: 3px;
    font-size: 12px;
    min-width: 30px;
  }

  .package-info__discount-mobile {
    display: block;
  }
}
/* End */

@media screen and (max-width: 375px) and (min-width: 360px) {
  .package-bxu {
    font-size: 19px;
  }
}

@media screen and (max-width: 360px) {
  .package-bxu {
    font-size: 18px;
  }
}

.card-notification {
  padding: 1.25rem 0 0.5rem;
}
.card-notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}
.card-notification__message {
  text-align: justify;
  text-align-last: center;
}
.card-notification__message {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
}
.card-notification__desc {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  text-align: center;
}
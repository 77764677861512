#phone,
#email {
  display: flex;
  justify-content: space-between;
}
.ant-input-affix-wrapper {
  justify-content: space-between;
}

#phone input,
#email input {
  display: block;
  flex-basis: 100%;
}

.validate-status {
  font-size: 16px;
  font-weight: 700;
  padding: 4px 12px;
  border-radius: 10px;
  background: #fff;
  text-align: center;
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
}
.validate-status.active {
  color: #56B947;
  border: 1px solid #56B947;
}
.validate-status.inactive {
  color: #56B947;
  border: 1px solid #56B947;
}

.user-info-form .ant-form-item-label label {
  font-size: 20px !important;
}

.user-info-form input,
.user-info-form .ant-picker-input input,
.user-info-form .ant-form-item-control label {
  font-size: 18px !important;
}

.user-info-form .ant-radio-wrapper:first-child {
  margin-right: 50px;
}

.ant-form-item.custom-field .ant-col.ant-col-xxl-19 {
  margin-left: -10px !important ;
}
@media screen and (max-width: 1600px) {
  .ant-form-item.custom-field .ant-col.ant-col-xxl-19 {
    margin-left: 3px !important ;
  }
}

@media screen and (max-width: 768px) {
  .user-info-form .ant-form-item-label label {
    font-size: 18px !important;
  }
  .validate-status {
    font-size: 13px;
    /* height: 38px;
    line-height: 26px; */
  }
}

@media screen and (max-width: 600px) {
  .user-info-form input,
  .user-info-form .ant-picker-input input,
  .user-info-form .ant-form-item-control label {
    font-size: 14px !important;
  }

  .validate-status {
    font-size: 12px;
    padding: 4px;
    line-height: 22px;
    margin-top: 11px;
  }
}

@media screen and (max-width: 610px) {
  #phone input,
  #email input {
    display: block;
    flex-basis: 65%;
  }
}

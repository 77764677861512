.noti-modal {
  color: #05422c;
  font-size: 40px;
  font-weight: 700;
  width: 400px !important;
}

.failed,
.success {
  display: block;
  margin: 40px auto;
}

.noti-content h2 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin: 24px 0;
}

.noti-content p {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin: 24px 0 40px 0;
}

.noti-groupBtn {
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
}

.noti-groupBtn button {
  padding: 16px 24px;
  border: 1px solid #05422c;
  border-radius: 10px;
  background: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  width: 140px;
}

.noti-groupBtn .confirm-btn {
  background: var(--main-color);
  color: #fff;
  border-color: var(--main-color);
}
.confirm-btn a {
  color: #fff;
}

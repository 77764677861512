.container {
  width: 100%;
  margin: 0 auto;
  min-width: 70vw;
  margin-top: 80px;
}

.box {
  border-radius: 10px;
  background: #ffffff;
  /* border: 1px solid #dddddd; */
  padding: 2rem 0.5rem 1rem;
  text-align: center;
}

.body {
  padding: 1rem 0.5rem;
}

.body h3 {
  padding-top: 18px;
  font-size: 20px;
  line-height: 1.5;
}
.message {
  padding: 0.5rem 0;
  font-size: 16px;
}

.auto-redirect {
  padding: 8px 0 8px;
  font-size: 14px;
  font-style: italic;
  color: rgb(86, 185, 71);
}
.auto-redirect a {
  color: red;
}

@media screen and (max-width: 610px) {
  .container {
    margin: 10px 0 20px 0;
  }
}

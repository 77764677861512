.change-phone-form h2 {
  text-align: center;
  font-size: 26px;
  margin-bottom: 24px;
}
.change-phone-form p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: -16px;
  text-align: center;
}
.change-phone-form .label {
  text-align: left;
  margin-bottom: 8px;
  margin-top: 2px;
  font-family: 'Calibri', sans-serif;
}
.change-phone-form label {
  font-weight: 400 !important;
  font-size: 16px !important;
}
.change-phone-form .otp-submit {
  display: block;
  height: 40px;
  width: 100%;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  font-weight: 700;
}
.single-btn button,
.confirm-btn {
  border-radius: 8px;
  background: var(--main-color);
  font-size: 16px;
  font-weight: 700;
  padding: 7px 34px;
  border: none;
  color: #fff;
  cursor: pointer;
  height: 100%;
}

.btn-group {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
}

.btn-group button:first-child {
  background: #cdcdcd;
  color: #868686;
  font-weight: 400;
}

@media screen and (max-width: 610px) {
  .change-phone-form h2 {
    font-size: 20px;
  }
  .change-phone-form p {
    font-size: 15px;
  }
  .btn-group button {
    font-size: 14px;
    padding: 10px 16px;
  }
}

.payment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 170px;
  background-color: #fff;
  box-shadow: 0px 2px 21px 0px rgba(0, 0, 0, 0.1);
}

.payment-header__user {
  display: flex;
  gap: 30px;
  align-items: center;
}

.header-user__wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 6px;
}

.payment-header__user .header-user {
  display: flex;
  gap: 8px;
  cursor: pointer;
}

.header-left,
.header-respon {
  display: flex;
  gap: 40px;
  font-size: 16px;
  font-weight: 700;
}
.header-left a,
.header-respon a {
  text-decoration: none;
  color: #000;
}

.header-respon {
  display: none;
}

.header-left {
  display: flex;
  align-items: center;
}

.user-summary {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 10px;
  background-color: rgba(255, 154, 0, 0.1);
  border-radius: 50px;
}

.user-summary-nxu-icon {
  width: 20px;
  height: 20px;
  /* border-radius: 50%; */
  margin-left: -2px;
}

.user-summary-plus {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.user-summary span {
  color: #3b3935;
  font-family: Calibri;
  font-size: 20px;
  margin-top: 2px;
  font-weight: 700;
  line-height: normal;
  display: inline-block;
  margin-left: 2px;
}

.user-info-wrapper {
  display: none;
}

/* Dropdown */
.drop-down-container {
  min-width: 200px;
}

.dropdown-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.header-user__avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.header-user__avatar-name {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 38px;
  background-color: rgba(0, 0, 0, 0.25);
  position: relative;
}
.header-user__avatar-name span {
  transform: scale(1) translateX(-50%);
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
  color: #fff;
  font-weight: 700;
}

.drop-down-item {
  display: block;
  padding: 4px 10px;
  color: #05422c !important;
  font-size: 16px;
  cursor: pointer;
}

.drop-down-logout {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.drop-down-logout-icon {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

.user-name {
  color: #05422c;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.header-btn {
  padding: 12px 24px;
  border-radius: 5px;
  background: var(--main-color);
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  text-decoration: none;
}

.login-group {
  display: flex;
  align-items: center;
  gap: 24px;
}

.instruction-payment {
  margin-right: 16px;
  color: #05422c;
  cursor: pointer;
}

.login-group button {
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  background-color: var(--main-color);
  padding: 12px 20px;
  cursor: pointer;
  font-family: 'Calibri', sans-serif;
}

.login-group .guide-btn {
  border: none;
  color: #05422c;
  background-color: transparent;
  display: block;
  font-weight: 700;
}

.login-group .login-btn {
  padding: 10px 15px;
}

.login-group .register-btn {
  border: 1px solid var(--main-color);
  color: var(--main-color);
  background-color: transparent;
  padding: 10px 27px;
}

.mobile-history {
  display: none;
  text-align: center;
}

.guide-block {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  padding: 12px 0;
  border-top: 1px solid #cdcdcd;
  display: none;
  color: #05422c;
}

.header-user__coint {
  position: relative;
}
.arrow-down {
  margin-left: 6px;
  margin-bottom: -1.4px;
}

.disable-hover:hover {
  pointer-events: none !important;
  /* Add any other styles to visually disable the hover effect */
}

.guide-btn-mobile {
  display: none;
}

.header-link {
  font-size: 20px;
  font-weight: 400;
}

/* Responsive */
@media screen and (max-width: 992px) {
  .payment-header {
    padding-left: 80px;
    padding-right: 80px;
    flex-wrap: wrap;
    align-items: center;
  }
  .payment-header__user {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 724px) {
  .login-group .guide-btn {
    display: none;
  }
  .guide-block {
    display: block;
    padding-bottom: 0;
    margin-top: 16px;
  }

  .guide-btn-mobile {
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 12px 0;
    border: 1px solid #ddd;
    color: #3b3935;
  }

  .payment-header {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .payment-header {
    padding: 4px 0 0 0;
  }

  .header-left {
    display: none;
  }

  .mobile-history {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #ddd;
    margin-top: 8px;
  }

  .header-link,
  .mobile-history p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    display: block;
    text-align: center;
    padding: 12px 0;
  }
  .header-link {
    flex-basis: 50%;
  }
  .header-link:last-child {
    border-left: 1px solid #cdcdcd;
  }
  .header-link:first-child {
    border-right: 1px solid #cdcdcd;
  }
}

@media screen and (max-width: 610px) {
  .header-respon {
    gap: 0;
    border: 1px solid #cdcdcd;
    display: none;
  }

  .user-summary {
    display: none;
  }

  .header-respon a {
    display: block;
    flex-basis: 50%;
    text-align: center;
    padding: 10px 0;
  }
  .header-respon a:last-child {
    border-left: 1px solid #cdcdcd;
  }

  .payment-header__logo {
    padding-left: 16px;
    margin-bottom: 10px;
  }
  .payment-header__user {
    padding-right: 16px;
  }

  .login-group {
    margin-right: 16px;
    margin-bottom: 12px;
  }

  .guide-block {
    padding-bottom: 12px;
    margin-top: 0;
  }

  /* User info */

  .user-info-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    padding: 2px 16px;
    background-color: rgba(255, 154, 0, 0.1);
    border-radius: 50px;
  }

  .user-info-group {
    border-right: 1px solid #ffe9c8;
    padding-right: 6px;
  }

  .user-info-name {
    color: #000;
    font-weight: 700;
  }

  .user-info-coin {
    display: flex;
    align-items: end;
    color: #ff6c00;
    font-weight: 700;
  }

  .user-info-group-coin {
    margin-left: 6px;
  }
}

@media screen and (max-width: 600px) {
  .payment-header__logo svg {
    width: 76px;
    height: 52px;
  }
  .header-btn {
    display: none;
  }
  .login-group {
    gap: 12px;
  }
  .login-group button {
    padding: 10px;
  }
  .header-user {
    margin-top: -12px;
  }
}

/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.modal__header, .modal__body {
    text-align: center;
    font-size: 24px;
}

.modal__title {
    text-transform: uppercase;
}

.modal__body .text__message {
    padding: 48px 0;
}

.modal__body .icon {
    padding-bottom: 12px;
}
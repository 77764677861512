/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.container {
  text-align: center;
  padding-top: 86px;
  padding-bottom: 108px;
}

.wrapper {
  font-size: 17px;
  font-weight: 700;
  padding-top: 47px;
  line-height: 18.75px;
}

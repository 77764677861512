.login-modal {
  color: #05422c;
  font-size: 40px;
  font-weight: 700;
}

.login-content h2 {
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.login-content > svg {
  display: block;
  margin: 0 auto;
  margin-top: 24px;
}

.login-btn {
  display: block;
  margin: 0 auto;
  border-radius: 10px;
  background: var(--main-color);
  border: none;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 12px 48px;
  cursor: pointer;
  margin: 48px auto;
}

@media screen and (max-width: 600px) {
  .login-content p {
    font-size: 16px;
  }
}

.payment-footer {
  background: #353c36;
  padding: 40px 0 20px 0;
}
.payment-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2.5em
}
.footer-logo {
  display: inline-block;
}

.footer-logo img {
  width: 102px;
}

.footer-container {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.footer-content {
  color: #fff;
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.footer-content h4 {
  color: var(--main-color);
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 10px;
}

.footer-content p {
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}

.payment-footer .copy-right {
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-top: 15px;
}

.footer-menu {
  display: flex;
  gap: 5px;
  font-size: 13px;
  margin-top: 10px;
}

/* .footer-menu a {
  color: #fff;
  border-right: 1px solid #fff;
  padding-right: 5px;
  line-height: 10px;
} */

.footer-menu div {
  gap: 5px;
  display: flex;
}

.footer-menu a {
  color: #fff;
  border-right: 1px solid #fff;
  padding-right: 5px;
  line-height: 10px;
}

.footer-menu a:hover {
  cursor: pointer;
  color: var(--main-color);
}

.footer-menu a:last-child {
  border: none;
}

.footer-partner h5 {
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
}
.footer-partner > .container {
  padding-top: 1em;
}

/* Responsive */
@media screen and (max-width: 610px) {
  .payment-footer {
    padding: 36px 16px;
  }

  .footer-content {
    gap: 10px;
    display: grid;
  }

  .footer-content h4 {
    font-size: 16px;
  }
  .footer-content p {
    font-size: 13px;
  }
  .footer-logo img {
    width: 87px;
  }

  .payment-footer, .footer-container {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .footer-menu {
    flex-direction: column;
    gap: 8px;
  }
}

/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.wrapper .ant-modal-title {
    text-align: center;
    font-size: 20px;
}

.modal-title__btn_back {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
}

.modal-title__text {
    text-align: center;
    flex-grow: 1;
    font-weight: 700;
    font-size: 20px;
}

.modal-content .text-message {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.layout-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 24px 0;
}

.layout-header img {
  width: 28px;
  height: 28px;
}

.layout-header img:hover {
  cursor: pointer;
}

.layout-header span {
  font-size: 19px;
  color: #3B3935;
}

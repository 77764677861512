.payment-history {
  color: #05422c;
  border-radius: 10px;
  background: #fff;
  padding: 24px;
  margin-bottom: 48px;
  min-height: 545px;
}
.payment-history__title {
  font-size: 32px;
  font-weight: 700;
  position: relative;
  padding-left: 20px;
}

.payment-history__title::before {
  content: '';
  position: absolute;
  top: 0;
  width: 8px;
  background-color: var(--main-color);
  bottom: 0;
  left: 0;
}

.search-history {
  display: flex;
  gap: 24px;
  margin-top: 24px;
}
.search-history button {
  border-radius: 5px;
  background: var(--main-color);
  color: #fff;
}

.add-value {
  color: #56b947;
  font-weight: 700;
}
.subtract-value {
  color: #da0707;
  font-weight: 700;
}

.table-action {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px 16px;
  flex-wrap: wrap;
}
.table-action img {
  width: 60px;
  height: 60px;
}

.table-text {
  font-size: 18px;
  font-weight: 400;
  color: #05422c;
  font-family: 'Calibri', sans-serif !important;
  /* max-width: fit-content; */
}

/* Pagination */
.pagination {
  display: flex;
  align-items: center;
  gap: 24px;
  position: relative;
  margin-top: 30px;
  justify-content: center;
}
.pagination-btn {
  border: none;
  font-size: 21.04px;
  font-weight: 400;
  line-height: 163.688%;
  background-color: transparent;
}
.pagination-btn:hover {
  cursor: pointer;
}
.pagination-btn:first-child {
  color: #fff;
  padding: 2px 12px;
  border-radius: 6.575px;
  background: var(--main-color);
}
.pagination-direct {
  position: absolute;
  right: 0;
  top: 0px;
}
.pagination-direct span {
  display: inline-block;
  margin-left: 8px;
}
.pagination-direct span:hover {
  cursor: pointer;
}

/* Responsive */
@media screen and (max-width: 768px) {
  .search-history {
    margin-bottom: 32px;
  }
  .search-history button {
    display: block;
    margin: 0 auto;
  }
}

@media screen and (max-width: 610px) {
  .payment-history {
    padding-left: 8px;
    padding-right: 8px;
  }
  .pagination-direct {
    display: none;
  }
  .item-left {
    font-size: 16px;
  }
  .table-text {
    font-size: 14px;
  }
  .table-action img {
    width: 40px;
    height: 40px;
  }
  .pagination button {
    font-size: 16px;
  }
}

.support-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.user-info-name {
  color: #3b3935;
  font-size: 40px;
  font-weight: 700;
}
.user-info-name span {
  color: #ff6c00;
}
.support-text {
  color: #8e8e8e;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}
/*------- region "tab styles" -------*/
.tab-label {
  font-size: 24px;
  font-weight: 700;
}
.tab-label-active {
  color: #ff6c00;
  font-size: 24px;
  font-weight: 700;
}
/*------- endRegion -------*/
@media screen and (max-width: 1400px) {
  .user-info-name {
    font-size: 36px;
  }
}

@media screen and (max-width: 1200px) {
  .user-info-name {
    font-size: 32px;
  }
}

@media screen and (max-width: 976px) {
  .user-info-name {
    font-size: 28px;
  }
}

@media screen and (max-width: 480px) {
  .user-info-name {
    font-size: 24px;
    margin-bottom: 5px;
  }
  .support-text {
    font-size: 20px;
  }
  .tab-label {
    font-size: 20px;
  }
  .tab-label-active {
    font-size: 20px;
  }
}

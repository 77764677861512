/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.vnpay-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 100px 0;
}

.vnpay-container img {
  width: 70%;
  min-width: 750px;
}

h1 {
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .vnpay-container {
    padding: 30px 0 50px 0;
  }

  .vnpay-container img {
    width: 100%;
    min-width: unset;
  }

  h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 428px) {
  h1 {
    font-size: 18px;
  }

  .vnpay-container {
    padding: 20px 0 50px 0;
  }
}
